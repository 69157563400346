import React from "react"
import { Link } from "gatsby"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"

const Menu = ({ open, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link to="/Elsa/" tabIndex={tabIndex}>
        ELSA
      </Link>
      <Link to="/Robot/">스마트 모빌리티</Link>
      <Link to="#none" className="MenuMobile fobMenuMobile">
        사업 영역
        <ul>
          <li>
            <Link to="/business/Elevator/">엘리베이터</Link>
          </li>
          <li>
            <Link to="/business/Escalator/">에스컬레이터</Link>
          </li>
          <li>
            <Link to="/business/ParkingTower/">기계식주차장</Link>
          </li>
          <li>
            <Link to="/business/Si/">통합방재시스템&nbsp;SI</Link>
          </li>
          <li>
            <Link to="/business/Hvac/">HVAC&nbsp;공조시스템</Link>
          </li>
          <li>
            <Link to="/business/Bems/">BEMS</Link>
          </li>
          <li>
            <Link to="/business/Logistics/">물류</Link>
          </li>
          <li>
            <Link to="/business/Ai/">AI 서비스</Link>
          </li>
        </ul>
      </Link>
      <Link to="#none" className="MenuMobile stanProjMenuMobile">
        표준화 활동
        <ul>
          <li>
            <Link to="/standard/InterStand/">
              국제&nbsp;표준 ITU-T Y.Series
            </Link>
          </li>
          <li>
            <Link to="/standard/InterStandY4420/">
              국제&nbsp;표준 ITU-T Y4420
            </Link>
          </li>
          {/* <li>
            <Link to="/standard/NationStand/">국가&nbsp;표준</Link>
          </li> */}
          {/* <li>
            <Link to="/standard/TtaStand/">TTA&nbsp;표준</Link>
          </li> */}
          <li>
            <Link to="/standard/GroupStand/">단체&nbsp;표준</Link>
          </li>
        </ul>
      </Link>
      <Link to="/AboutUs/" tabIndex={tabIndex}>
        about us
      </Link>
      <Link to="/Press/" tabIndex={tabIndex}>
        news &amp; press
      </Link>
      <Link to="/Contact/" tabIndex={tabIndex}>
        contact us
      </Link>
      <Link to="/en/" tabIndex={tabIndex}>
        Eng
      </Link>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
//<a href="/" tabIndex={tabIndex}>
//<span aria-hidden="true">📩</span>
//Contact
//</a>
