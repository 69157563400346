import React from "react"
import logo from "../../img/logo/logo.svg"
import mapIcon from "../../img/mapIcon.svg"
import Link from "gatsby-plugin-transition-link"
import {
  companyName,
  companyAdd,
  companyTel,
  companyFax,
  companyMail,
  companyHp,
} from "../configs/ShortCode"
import logoTwit from "../../img/logo/logo_twit.png"
import logoYout from "../../img/logo/logo_yout.png"

const textWstrong = { fontWeight: "bold" }
function Footer() {
  return (
    <div className="sectionWrap footerWrap">
      <p className="companyBottomLogo imgWrap">
        <img src={logo} alt="logo" />
      </p>
      <ul className="companyInfo">
        <li style={textWstrong}>{companyName}</li>
        <li>
          {companyAdd}
          <Link to="/Contact">
            <p className="mapIcon">
              <img src={mapIcon} alt="" />
            </p>
            지도보기
          </Link>
        </li>
        <li>
          <span style={textWstrong}>T</span> {companyTel}
        </li>
        <li>
          <span style={textWstrong}>F</span> {companyFax}
        </li>
        <li>
          <span style={textWstrong}>M</span> {companyMail}
        </li>
        <li>
          <span style={textWstrong}>H</span> {companyHp}
        </li>
      </ul>
      <div className="snsLinks">
        <ul>
          <li className="twitter">
            <a href="https://twitter.com/_m2mtech" title="twitter">
              <img src={logoTwit} alt="" />
            </a>
          </li>
          <li className="youtube">
            <a
              href="https://www.youtube.com/channel/UCJS2s-_iyJ34l7bBPdmkEGQ"
              title=""
            >
              <img src={logoYout} alt="youtube" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
