import { Link } from "gatsby"
import React, { useMemo } from "react"
import logoW from "../../img/logo/logo_w.svg"
import { useLocation } from "@reach/router"
import NavMobile from "./NavMobile"

function Header() {
  const location = useLocation()
  const path = useMemo(() => {
    const parts = location.pathname.split("/")
    if (parts.length > 0) {
      return parts[1]
    }
    return ""
  }, [location.pathname])

  return (
    <header className={`headerWrap ${path}`}>
      <p className="companyTopLogo imgWrap">
        <Link to="/">
          <img src={logoW} alt="" />
        </Link>
      </p>
      <nav className="gnbUl">
        <Link to="/Elsa/">ELSA</Link>
        <Link to="/Robot/">스마트 모빌리티</Link>
        <Link to="#none" className="dropDownMenu fobMenu">
          사업 영역
          <ul>
            <li>
              <Link to="/business/Elevator/">엘리베이터</Link>
            </li>
            <li>
              <Link to="/business/Escalator/">에스컬레이터</Link>
            </li>
            <li>
              <Link to="/business/ParkingTower/">기계식주차장</Link>
            </li>
            <li>
              <Link to="/business/Si/">통합방재시스템&nbsp;SI</Link>
            </li>
            <li>
              <Link to="/business/Hvac/">HVAC&nbsp;공조시스템</Link>
            </li>
            <li>
              <Link to="/business/Bems/">BEMS</Link>
            </li>
            <li>
              <Link to="/business/Logistics/">물류</Link>
            </li>
            <li>
              <Link to="/business/Ai/">AI 서비스</Link>
            </li>
          </ul>
        </Link>
        <Link to="#none" className="dropDownMenu stanProjMenu">
          표준화 활동
          <ul>
            <li>
              <Link to="/standard/InterStand/">
                국제&nbsp;표준 ITU-T Y.Series
              </Link>
            </li>
            <li>
              <Link to="/standard/InterStandY4420/">
                국제&nbsp;표준 ITU-T Y4420
              </Link>
            </li>
            {/* <li>
              <Link to="/standard/NationStand/">국가&nbsp;표준</Link>
            </li> */}
            {/* <li>
              <Link to="/standard/TtaStand/">TTA&nbsp;표준</Link>
            </li> */}
            <li>
              <Link to="/standard/GroupStand/">단체&nbsp;표준</Link>
            </li>
          </ul>
        </Link>
        <Link to="/AboutUs/">About Us</Link>
        <Link to="/Press/">News &amp; Press</Link>
        <Link to="/Contact/">Contact Us</Link>
        <Link to="/en/">Eng</Link>
      </nav>
      <nav className="gnbUlMobile">
        <NavMobile />
      </nav>
    </header>
  )
}

export default Header
