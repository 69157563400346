import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import standProjMain from "../../img/standProjMain.png"
import standMainImg01 from "../../img/t_28.jpg"
import standMainImg02 from "../../img/t_27.jpg"
import standMainImg03 from "../../img/connectivity.jpg"
import Itut27 from "../../img/ITUT27.jpg"
import Itut28 from "../../img/ITUT28.jpg"

const InterStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            International <span>standard</span>
          </h2>
          <h3 style={{ paddingTop: 20 }}>ITU-T SG20 Series Y</h3>
          <p className="bodyText">
            (주)엠투엠테크는 한국전자통신연구원과 협력하여 국제표준 기구인 ITU-T
            SG20 (Internet of Things and Smart Cities) 에 사물인터넷과
            스마트시티기술에 대한 표준개발에 참여하고 있습니다. 승강기, lift
            기기 사물인터넷에 적용되는 국제적인 표준을 제안하여 채택 되었으며
            이에 승인 프로세스를 진행하고 있습니다. 이러한 엠투엠테크의 노력은
            스마트 시티와 커뮤니티(SC&amp;C)의 관계 연구에 도움이 될 것으로
            기대하고, 국내를 넘어서 세계적으로 화두가 되고 있는 미래의 스마트
            시티 솔루션의 실제 적용을 계획하는데 기여할 것으로 기대합니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap itu">
        <div className="textWrap">
          <h2>ITU-T SG20 Series Y</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            국제전기통신연합(ITU)은 통신, 정보통신기술(ICT) 분야의 유엔
            전문기관입니다. ITU 전기통신 표준화 부문(ITU-T)은 ITU의 상설
            기관으로, ITU-T는 기술, 운용, 관세 문제를 연구하여, 전 세계적으로
            전기통신 표준화를 목표로 권고안을 발행하는 역할을 담당합니다.
            <br />
            <br />
            엠투엠테크와 한국전자통신연구원 (ETRI) 은 사물인터넷과 AI 분야의
            기술 연구의 전략적인 협력 동반자로 ITU-T SG20에 표준개발에
            협력하고있습니다. 2019년 12월 발간된 ITU-T Y-series (supplement 56)
            에 ETRI 와 함께 엠투엠테크의 스마트 엘리베이터 솔루션이
            기고되었습니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap ituContribution">
        <div className="textWrap">
          <h2>ITU-T SG20 contribution</h2>
          <p className="bodyText">
            “Framework of IoT based monitoring and management for Lift”
            <br />
            통신 네트웨크에 직접 연결할 수 없는 대부분의 리프트로 인해 통일화
            되지 않은 자체 데이터 형식과 통신네트워크의 상호 연결 프로토콜을
            적용해왔습니다. 이로인해 상호 운용성 문제가 끊임없이 발생하여
            엠투엠테크는 공통 프로토콜 과 데이터 형식 적용의 필요성을 인지하고
            상호 연결을 지원하는 게이트웨이와 웹으로 서비스되는 리프트
            모니터링시스템을 구축하였습니다.
          </p>
        </div>
        <div className="standMainImg">
          <p className="standMainImg01">
            <img src={standMainImg01} alt="" />
          </p>
          <p className="standMainImg02">
            <img src={standMainImg02} alt="" />
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrapMain">
          ITU-T SG20 Y-series (supplement 56) page.21 기고 내용
        </div>
        <h3>8.5 리프트 모니터링 서비스 </h3>
        <ul>
          <li>
            1. 사용 사례의 제목 (제목은 처리 된 응용 프로그램 영역과 엄격하게
            관련됨)
            <span>a. 사용 사례 이름 : 리프트 모니터링 서비스 </span>
            <span>
              b. 사용 사례의 ID (ID는 7 절의 ID 카테고리 2 (인프라)에 제공됨)
            </span>
            <span>c. 버전 / 개정 내역 (예 : 번호 / 월 / 년) : 1.0</span>
            <span>
              d. 출처 (국가 / ITU-T 회원 / ITU에 등록 된 조직) : 한국 (공화국) /
              ETRI
            </span>
          </li>
          <li>
            2. 사용 사례의 목적 (설명 내용이있는 제목과 일치)이 사용 사례는
            엘리베이터의 유지 관리 및 안전 서비스를 설명합니다. 스마트 시티
          </li>
          <li>
            3. 배경
            <span>
              a. 현재 관행 (사용 사례 구현으로 이익이되는 현재 프로세스 /
              컨텍스트) 현재 관리자 또는 검사자가 리프트 안전 검사를 수행하고
              사용자의 보고서를 통해 위험을 감지하고 비상 경고가 활성화됩니다.
            </span>
            <span>
              b. 사용 사례 필요 리프트 관리시 모니터링 시스템을 이용하여
              원격으로 안전 및 상태 점검이 가능합니다. 개인이 엘리베이터에
              갇히거나 고장 또는 정지와 같은 문제가 발생했을 때 제어하는 ​​것도
              가능합니다. 따라서 스마트 시티 운영자는 리프트 모니터링 시스템을
              이용하여 스마트 시티를 안전하고 편리하게 관리합니다.
            </span>
            <span>c. 국가 생태계 세부 사항 : 없음</span>
          </li>
          <li>
            4. 사용 사례의 도시 또는 커뮤니티
            <span>a. 도시 또는 커뮤니티 이름 : 서울 특별시</span>
            <span>b. 국가 : 한국 (공화국) </span>
            <span>c. 지역 : 아시아 </span>
            <span>d. 시 인구 : 10,124,579 (2017 년 12 월) </span>
            <span>
              e. 사용 사례 단계 (계획 단계 / 파일럿 단계 / 상업 단계) : 상업
              단계
            </span>
          </li>
          <li>
            5. 설명
            <span>
              a. 행위자 및 업무 역할에 따른 생태계 설명 스마트 시티 운영 센터는
              승강기와 관리실과 연결되어 있습니다. 스마트 시티 운영 센터는
              리프트 관리자 또는 관리실에 리프트 모니터링 정보를 요청하고 리프트
              모니터링 정보를 공유합니다. 참고-이 사용 사례에서 스마트 시티 운영
              센터의 주요 역할은 도시 전체의 리프트를 모니터링하고 제어하는
              ​​것입니다.
            </span>
            <span>
              b. 상황 별 일러스트레이션 리프트 관리 및 안전을 위해 스마트 시티
              운영 센터는 실시간 모니터링, 제어, 진단 및 관리 기능을 수행하고
              통계 정보를 제공합니다.
              <img src={Itut27} alt="" />
              그림 8-9 – 스마트 시티 운영 센터와 리프트 시스템 연동
            </span>
            <span>c. 전제 조건 : 없음 </span>
            <span>
              d. 사전 조건 (있는 경우) 사용자와 엔지니어가 사용하는 응용
              프로그램이 다릅니다. 사용자 기기에서 제공되는 애플리케이션을 통해
              사용자는 스마트 시티 운영 센터에 위치 정보 업데이트를 제공하고
              인근 리프트 실패 정보를 수신한다. 스마트 시티 운영 센터는 고장난
              리프트에서 최단 거리에 위치한 엔지니어에게 알립니다.
            </span>
            <span>
              e. 트리거 : 비상 경보, 서비스 중단 (고장) 또는 화재 작업이보고 될
              때.
            </span>
            <span>
              f. 시나리오
              <span>
                1) 스마트 시티 운영 센터와 승강기 연동 <br />- 엘리베이터에서
                비상 경보, 서비스 중단 또는 화재 발생 이벤트가 감지되는 경우.
                스마트 시티 운영 센터로 이벤트가 전송됩니다. <br />- 스마트 시티
                운영 센터는 주변 사용자에게 이벤트를 전송하여 추가 사고를
                예방하거나 엔지니어에게 고장을 수리합니다. <br />- 엔지니어가
                이벤트 검사를 완료하고 완료 메시지를 스마트 시티 운영 센터로
                보냅니다. 스마트 시티 운영 센터는 엔지니어로부터 메시지를 받거나
                리프트 시스템에서 직접 전체 이벤트를 수신합니다.
              </span>
            </span>
            <span>
              g. 프로세스 흐름도 <br />
              1) 스마트 시티 운영 센터와 리프트 시스템 연동 (그림 8-10 참조)
              <br />
              <img src={Itut28} alt="" />
              <br />
              그림 8-10 – 스마트 시티 운영 센터와 승강기 시스템 연동 프로세스
              흐름
            </span>
            <span>h. 사후 조건 (있는 경우) : 없음 </span>
            <span>
              i. 정보 교환 <br />
              리프트 등록 번호를 기준으로 리프트를 관리하고 위치 정보를
              저장합니다. 리프트 시스템의 위치 정보와 고장 관련 정보가
              교환됩니다. <br />
              스마트 시티 운영 센터는 고장난 리프트 근처의 사용자에게 고장
              경보를 보내고 고장에 대한 정보를 리프트에 가장 가까운 엔지니어에게
              보냅니다.
            </span>
          </li>
          <li>
            6. 아키텍처 고려 사항
            <span>
              a. 배포 고려 사항 <br />
              리프트의 원격 모니터링을위한 사물 인터넷 (IoT) 게이트웨이와 같은
              장비가 지원되어야합니다. 이벤트 전송을위한 표준 프로토콜 및 데이터
              형식을 정의해야합니다.
            </span>
            <span>b. 지리적 고려 사항 : 없음 </span>
            <span>
              c. 통신 인프라 <br /> 리프트는 유선 또는 무선 통신 링크를 통해
              모니터링 시스템과 통신합니다.
            </span>
            <span>d. 성능 기준 : 없음 </span>
            <span>e. 인터페이스 요구 사항 : 없음</span>
            <span>f. 사용자 인터페이스 : 없음 </span>
            <span>g. 플랫폼에서 애플리케이션에 노출 될 API : 없음 </span>
            <span>
              h. 데이터 관리 : 리프트 번호는 고유하며 7 자리 숫자로 구성됩니다.
              처음 두 자리는 면적과 리프트 유형을 나타냅니다. 그런 다음 나머지
              숫자는 순서대로 할당되고 특정 주소 정보 및 리프트 사양과 같은 기타
              리프트 관련 정보와 일치합니다.
            </span>
            <span>i. 데이터 백업, 보관 및 복구 : 없음</span>
            <span>j. 원격 장치 관리 : 없음</span>
            <span>k. 시작 / 종료 프로세스 : 없음</span>
            <span>
              l. 보안 요구 사항 <br /> 수집 된 데이터는 안전한 환경에서 저장 및
              처리되어야합니다.
            </span>
          </li>
          <li>7. 잠재적 인 시장 성장 예측 : 없음 </li>
          <li>8. 구현 제약 (사용 사례 지원을위한) : 없음 </li>
          <li>9. 법정 준수 및 관련 규정 : 없음 </li>
          <li>10. 사용 가능한 국제 표준 : 없음 </li>
          <li>
            11. 참조 (표준 또는 기타 유용한 정보와 관련됨) ) :
            https://unicorn.m2mtech.co.kr (국문 만 해당)
          </li>
          <li>12. 일반 사항 : 없음</li>
        </ul>
      </div>
      <div className="sectionWrap standGallery">
        -<br />
        https://unicorn.m2mtech.co.kr 는 ELSA.CLOUD 의 초기 버전으로 &nbsp;
        <b>https://elsa.cloud</b>로 변경되었으며 이에 관련하여 &nbsp;
        <Link to="/Elsa/">https://m2mtech.co.kr/Elsa</Link> 방문 혹은
        info@m2mtech.co.kr 문의사항 보내주시기 바랍니다.
      </div>
    </div>
  </Layout>
)

export default InterStand
